import React from "react";

import { Link } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not Found" />
      <h1>Not Found :(</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <Link to="/">Return to the Home Page</Link>
    </Layout>
  );
};

export default NotFoundPage;
